.space{

    margin-top:-35px;
}
.spacestate{
    margin-top:-20px;
}
.districtspace{
    margin-top: -20px;
}
.Talukaspace{
    margin-top:-27px;
}

.Cityspace{
    margin-top:-10px;
}
#select{font-size: 13px;}
.bgcolor{
  background-color: #DAD8D8;
  font-size: 14px;
  height: 30px;
   line-height: 32px;
  
  
    
   
}
.Savedata{
    font-weight: bold;
    

}
.VehicleCategory{
  Margin-top:-20px;
    
}
.modelcategory{
    Margin-top:-15px;
      
  }
  .modelDiscription{
    Margin-top:-40px;
  }
  .personalinfospace{
      Margin-top:-15px;
  }
  
  .purchasespace{
    Margin-top:-22px;
  }
  .AddPurchaseModel{
    Margin-top:-20px;
  }
  .AddRcSpace{
    Margin-top:-20px;
  }
  .userspace{
      Margin-top:-22px;
  }
  
  .CustomBreadCrumb{
   
    margin-left: 172px;
    margin-top:-12px;
  }
  .contactCustomBreadCrumb{
    margin-left: 178px;
    margin-top:-12px;
  }
  .StateCustomBreadCrumb{
    margin-left: 188px;
    margin-top:-12px;
  }
  .CustomBreadCrumbtaluka{
   
    margin-left: 195px;
    margin-top:-12px;
  }
  .CompanyCustomBreadCrumb{
    margin-left: 175px;
    margin-top:-12px;
  }
  .CustomBreadCrumbcity{
   
    margin-left: 210px;
    margin-top:-12px;
  }
  .CustomBreadCrumbadd{
    margin-left: 280px;
    margin-top:-12px;
  }
  #VehicleCategory{
    margin-left: 110px;
  }
  #ContactModel{
    margin-left: 60px;
  }
  .personalinfoselect{
    margin-top: -10px;
  }
  #UserModel{
    margin-left: 40px;
  }
  #partyvoucher{
    margin-left:139px;
  }
  .Purchasemargin{
    margin-top: -30px;
  }
  .CalendorIcon{
   margin-left: 150px;
   
  }
  .spaceing{
    margin-top: -20px;
  }
  .spacecheck{
    margin-top:-12px;
  }
  .checkboxpadding{
    padding-top: 33px;
    margin-left: 10px;
  }
  
  
  .purchasecheckbox{
      margin-top:30px;
  }
  .alldoccheckbox{
    margin-top:20px;
    margin-left:10px;
}
  .statepadding{
    margin-left: 30px;
  }
  .CustomBreadCrumbaddvoucher{
    margin-left: 340px;
  }
  .CustomBreadCrumbvehiclemodel{
    margin-left: 186px;
  }
  .UserMasterCustomBreadCrums{
    margin-left: 178px;
    margin-top:-12px;
  }
  .Cancelbutton{
    margin-top: 22px;
    margin-left: -100px;
  }
  .StateCancelbutton{
    margin-top: 22px;
    margin-left: -55px
  }
  .talukatabletopmargin{
    margin-top: -25px;
  }
  
  .SaveCancelbutton{
    margin-left: -20px;
    margin-top: -20px;
  }
  .error label {
    color: #cc0033;
  }
  .error-message {
    color: #cc0033;
    display: inline-block;
    font-size: 12px;
    line-height: 15px;
    margin: 5px 0 0;
  }
  .searchmargin{
    margin-top: -45px;
  }
  
  .citysearchmargin{
    margin-top: -15px;
  }
  .companysearchmargin{
    margin-top:-55px;
  }
  .searchicon{
    margin-top:-20px;
  }
  .countrysearchicon{
    margin-top:-10px;
    
  }
  .input-group.md-form.form-sm.form-2 input.lime-border {
  border: 1px solid #e6e3e3;
  box-shadow: 2px 2px #e6e3e3;
  
}

#DistrictCustomBreadCrumb{
   
  margin-left: 178px;
  margin-top:-12px;
}
.DistrictCancelbutton{
  margin-left: 40px;
  margin-top: 20px;
}
.districttable{
  margin-top:-12px;
  
}
.talukarowmargin{
  margin-top: -35px;
}
.contactsavebutton{
  margin-left:-20px;
  margin-top: 18px;
  width: 106px;
}

.searchtopmargin{
  margin-top:-20px;
}
.purchasesearchicon{
  
 margin-top: -20px;
  

}
.Rccheckbox{
  margin-top:35px;
 padding-left: 25px;
}
.Rcsavebutton{
  margin-left:-20px;
}
.Rcsearch{
  margin-top:-15px;
}
.partyvocuhersearch{
  margin-top: 22px;
  margin-left: -10px;
}
.partyvouchertable{
  margin-top:-25px;
}
.rcmasterbreadcrumbs{
  margin-left: 218px;
}
.aimscenter{
  margin-left: 90px;
  color: gray;
}


.purchaseaddnew{
  margin-left: -20px;
  width: 106px;
}

.cityName{
  width: 170px;
}
/*
.DistrictName{
  width:105px;
}
*/

.AddpurchaseRadio{
  margin-left: 10px;
  
 
}
.Addpurchasecheckbox{
  margin-left: 12px;
}
.radiochecked{
  margin-top:-10px;
  margin-left: 14px;
}
.purchasedetailmargin{
  margin-left: -20px;
}
.purchasedetailmarginlabel{
  margin-left:8px;
}
.purchasedetailmarginlabe{
  margin-left:-20px;
}
.purchasedetailcompany{
  margin-left: -90px;
}
.purchasedetailfor{
  margin-left: -50px;
}
.purchasedetailmarginfor{
  margin-left: -34px;
}
.purchasedetailmarginforscrap{
  margin-left:6px;
}
.purchasedetailfor{
  margin-left: 30px;
}
.purchasedateymodelyearlabel{
  margin-left:12px;
}
.purchasedetailmodelyearforvender{
  margin-left: -34px;
}
.purchasevendor{
  margin-left: 8px;
}
.purchaseAmout{
  margin-left:-80px;
}
.purchaseamountmargin{
  margin-left:-px ;
}
.contactmobile{
  margin-left: 15px;
}
.purchasedetailmarginlabe{
  margin-left: -16px;
}
.stockdata{
  margin-left: -46px;
}
.rcbookid{
  margin-left: -12px;
}
.removeRowSpace{
  margin-bottom:-25px;
}
.checkmark {
 
 
  
  height: 20px;
  width: 20px;
 
}
@media(max-width:468px){
  
  .savebutton{
    margin-top: -20px;
    padding-bottom: 20px;
  }
  .countrysavebutton{
    width: 100%;
    margin-top: -80px;
    margin-left: -2px;
  }
  .countrycancelbutton{
    width: 100%;
    margin-top: -15px;
    margin-left: -2px;
  }
  .selectstate{
    margin-top: -30px;

  }
  .selectstate input{
    width: 100%;
  }
  .Statesavebutton{
    width: 100%;
    margin-left: -2px;
    margin-top: -10px;
    
  }
  .Districtsavebutton{
    width: 100%;
    margin-left: -2px;
    margin-top: -10px;
    
  }
  .Statesavecancel{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
    margin-left: -0px;
  }
  .Districtsavecancel{
    width: 100%;
    margin-top: -10px;
    margin-bottom: 25px;
    margin-left: -0px;
  }
  .citysavecancel{
    width: 100%;
   
    margin-bottom: 25px;
    margin-left: -0px;
  }
  .DistrictState{
    margin-top:-25px;
  }
  .DistrictName{
    margin-top: -25px;
  }
  .citySaveButton{
    width: 100%;
  }
  
  .talukaName{
    margin-top: -30px;
  }
  .companysavebutton{
    width: 100%;
    margin-left: -0px;
  }
  .companycancelbutton{
    width: 100%;
    margin-top: 15px;
    margin-left: -0px;
  }
  .talukasavebutton{
    width: 100%;
    margin-top: -20px;
  }
  .talukacancelbutton{
    width: 100%;
   
  }
  .selectmodeltop{
    margin-top: -15px;
  }
  .vehicalmodelsavedata{
    width: 100%;
  }
  .vehiclemodelcancelsavedata{
    width: 100%;
  }
  .carddata{
    width: 200px;
  }
  .contactsavebutton{
    width: 100%;
    margin-top: -10px;
    margin-bottom: 25px;
    margin-left: -8px;
  }
  .browsedata{
    width: 220px;
    margin-left: 15px;
  }
  .ContactSave{
    width:100%;
    margin-top: -40px;
  }
  .contactcancel{
    width: 100%;
    margin-top: -10px;
  }
  .addresssavebutton{
    width: 100%;
    margin-top: -10px;
    box-sizing: border-box;
  }
  .addresscancelbutton{
    width: 100%;
  }
  .purchasesave{
    width: 100%;
    margin-left: -0px;
  }
  .purchaseCancal{
    width: 100%;
    margin-left: -0px;
  }
  .purchaseaddnew{
    width: 100%;
    margin-left: 3px;
  }

  
}

@media(max-width:767px){
  .countrysavebutton{
    width: 100%;
    margin-top: -60px;
    margin-left: -2px;
  }
  .countrycancelbutton{
    width: 100%;
    margin-top: -10px;
    margin-left: -2px;
  }
  .citysavebutton{
    margin-top: -15px;
    width: 100%;
    margin-left: -0px;
  }
  .citysavecancel{
    width: 100%;
   
    margin-bottom: 25px;
    margin-left: -0px;
  }
  .companysavebutton{
    width: 100%;
    margin-left: -0px;
  }
  .companycancelbutton{
    width: 100%;
    margin-top: 15px;
    margin-left: -0px;
  }
  .talukasavebutton{
    width: 100%;
    margin-top: -20px;
    margin-left: 0px;
  }
  .talukacancelbutton{
    width: 100%;
    margin-left: 0px;
  }
  .selectmodeltop{
    margin-top: -15px;
  }
  .vehicalmodelsavedata{
    width: 100%;
  }
  .vehiclemodelcancelsavedata{
    width: 100%;
  }
  .contactsavebutton{
    width: 100%;
    margin-top: -10px;
    margin-bottom: 25px;
    margin-left: -8px;
  }
  .browsedata{
    width: 265px;
    margin-left: 15px;
  }
  .ContactSave{
    width:100%;
    margin-top: -40px;
  }
  .contactcancel{
    width: 100%;
    margin-top: -10px;
  }
  .addresssavebutton{
    width: 100%;
    margin-top: -10px;
    box-sizing: border-box;
  }
  .addresscancelbutton{
    width: 100%;
  }
  .carddata{
    height: 1100px;
    width: 100%;
  }
  .purchasesave{
    width: 100%;
    margin-left: -0px;
  }
  .purchaseCancal{
    width: 100%;
    margin-left: -0px;
  }
  .purchaseaddnew{
    width: 100%;
    margin-left: 3px;
  }
  .Addpurchasesave{
    width: 100%;
    margin-left: 0px;
  }
  .AddpurchaseCancel{
    width: 100%;
    margin-left: 0px;
  }
  .addpurchasemodel{
    height: 2400px;
  }
  .AddpurchaseRc{
    width: 100%;
    margin-left: 0px;
  }
  .rcsave{
    width: 100%;
    margin-left: -0px;
  }
  .rccancel{
    width: 100%;
    margin-left: 0px;
  }
  .salessave{
    width: 100%;
    margin-left: -0px;
  }
  .salescancel{
    width: 100%;
    margin-left: -5px;

  }
  .Usercontactsavebutton{
    width: 100%;
    margin-left: -10px;
  }
  .addusersavedata{
    width: 100%;
  }
  .addusercanceldata{
    width: 100%;
  }
  
}


@media(min-width:768px) and (max-width:1023px){
  .selectstate{
    margin-left: -20px;
  }
  .browsedata{
    width: 200px;
  
  }
  
  .ContactSave{
    margin-top: -20px;
  }
  .contactcancel{
    margin-left: -230px;
    margin-top: -20px;
  }
  .contactmodelcard{
    height: 2000px;
  }
  .Statesavebutton{
    width: 380%;
    margin-top: -10px;
    margin-left: 2px;
  }
  .Statesavecancel{
    width: 380%;
    margin-bottom: 20px;
    margin-left: 2px;
  }
  
   
  
  .Districtsavebutton{
      width: 110px;
      margin-left: -26px;
      margin-top: 29px;
  }
  .DistrictState{
    margin-left: -26px;
  }
  .DistrictName{
    margin-left: -22px;
  }
  .Districtsavecancel{
    margin-top: 28px;
    margin-left: -20px;
  }
  .talukaName{
    margin-top: -30px;
  }
  .talukasavebutton{
    margin-top: -2px;
    margin-left: -25px;
  }
  .talukacancelbutton{
    margin-top: -3px;
    margin-left: -130px;
  }
  .citysavebutton{
    margin-top: -20px;
    width: 100%;
    margin-left: 0px;
 
  }
  .citysavecancel{
    margin-top: -23px;
    margin-left: -50px;
  }
  .companysavebutton{
    margin-top: 25px;
  }
  .companycancelbutton{
    margin-top: 25px;
    margin-left: -140px;
  }
  .countrysavebutton{
    margin-top: -60px;
    margin-left: -0px;
  }
  .countrycancelbutton{
    margin-top: -60px;
  }
.selectmodel{
    margin-left: -20px;
  }
  .modelName{
    width: 80px;
  }
  .browsefile{
    margin-top: 30px;

  }
  .addresssavebutton{
    width:120px;
    margin-top: -20px;
    
  }
  .addresscancelbutton{
    margin-left: 20px;
    margin-top: -20px;
  }
  .purchasesave{
    margin-top: 25px;
    margin-left: -0px;
  }
  .purchaseCancal{
    margin-top: 25px;
    margin-left: -70px;
  }
  .purchaseamount{
    width: 180px;
  }
  .vendordelear{
    margin-left: 160px;
  }
  .addpurchasemodel{
    height: 1700px;
  }
  .rcsave{
    width: 140px;
    margin-left: -24px;
    margin-top: 28px;
  }
  .rccancel{
    width: 140px;
    margin-left: 15px;
    margin-top: 28px;
  }
  .rccarddata{
    height: 1200px;
  }
  .salessave{
    width: 140px;
    margin-top: 27px;
  }
  .salescancel{
    margin-left: 90px;
    width: 150px;
    margin-top: 26px;
  }
  .Usercontactsavebutton{
    margin-left: -20px;
    margin-top: 18px;
    width: 105px;
  }
  
  
}
@media(min-width:1024px)and (max-width:1366px){
  .rccarddata{
    height: 1550px;
  }
  .carddata{
    height: 1500px;
  }
  .sign{
    margin-left: 5px;
  }
  .Districtsavebutton{
    margin-top: -24px;
    width: 110px;
  }
  .Districtsavecancel{
    margin-top: -20px;
    margin-left: -20px;
    margin-bottom: 20px;
  }
  .talukaName{
    margin-top: -40px;
   
  }
  .companysavebutton{
    margin-top: 25px;
  }
  .companycancelbutton{
    margin-top: 25px;
    margin-left: -45px;
  }
  .companyleftmargin{
    margin-left: -20px;
  }
  .talukasavebutton{
    margin-top: -13px;
    margin-left: -25px;
  }
  .talukacancelbutton{
    margin-top: -14px;
    margin-left: -60px;
  }
  .countrysavebutton{
    margin-left: -23px;
  }
  .countrycancelbutton{
    margin-left:-2px;
  }
  .selectmodel{
    margin-left: -20px;
  }
  .modelName{
    width: 80px;
  }
  .browsefile{
    margin-top: 30px;
  }
  .ContactSave{
    margin-top: -20px;
  }
  .contactcancel{
    margin-left: -360px;
    margin-top: -20px;
  }
  .addresssavebutton{
    margin-top: -20px;
  }
  .addresscancelbutton{
    margin-left: 40px;
    margin-top: -20px;
    
  }
  .purchasefor{
    margin-left:-20px;
  }
  
  .rcsave{
    width: 140px;
    margin-top: 28px;
    margin-left: -20px;
  }
  .rccancel{
    width: 140px;
    margin-left: 50px;
    margin-top: 28px;
  }
  .purchasesave{
    margin-top: 30px;
    margin-left: -20px;
  }
  .purchaseCancal{
   
    margin-left: -180px;
    margin-top: 28px;
  }
  .loanaccountno{
    margin-left: 25px;
  }
  .salessave{
    width: 140px;
    margin-top: 28px;
    margin-left: -15px;
  }
  .salescancel{
    width: 150px;
    margin-top: 28px;
    margin-left: -25px;
  }
  .Usercontactsavebutton{
    margin-left: -20px;
    margin-top: 18px;
    width: 105px;
  }
  
  
   
 
  
  
  
  
}
@media(min-width:1200px) {
  
  .addressinfo{
    margin-left: -20px;
    
  }
    .DistrictState{
      margin-left:-26px;
    }
    .DistrictName{
      margin-left: -26px;
    }
    .Districtsavebutton{
      width: 110px;
      margin-left: -26px;
      margin-top: 29px;
  }
  .Districtsavecancel{
    margin-top: 28px;
    margin-left: -5px;
    
  }
  .talukasavebutton{
    margin-top: -13px;
    margin-left: -25px;
  }
  .talukacancelbutton{
    margin-top: -14px;
    margin-left: -110px;
  }
  .selectstate{
    margin-left: -20px;
   }
   .companymargin{
     margin-top: 20px;
   }
 .Statesavebutton{
   
    
     margin-top: 28px;
     margin-left: -20px;
   
 }
 .Statesavecancel{
  
     margin-top: 25px;
 }
 .citysavebutton{
   margin-top: -20px;

 }
 .citysavecancel{
   margin-top: -23px;
   margin-left: -280px;
 }
 .companysavebutton{
   margin-top: 6px;
 }
 .companycancelbutton{
   margin-top: 6px;
   margin-left: -12px;
 }
 .countrysavebutton{
   margin-left: -20px;
 }
 .countrycancelbutton{
   margin-left:-2px;
 }
 .selectmodel{
   margin-left: -20px;
 }
 .modelName{
   width: 130px;
 }
 .sign{
   margin-left: 5px;
 }
 .ContactSave{
   margin-top: -20x;
   margin-left: -0px;
 }
 .contactcancel{
   margin-left: -530px;
   margin-top: -20px;
 }
 .browsefile{
  margin-top: 30px;
}
.addresssavebutton{
 
  margin-top: 32px;
 
}
.addresscancelbutton{
  margin-top: 32px;
  margin-left: -90px;
 
}
.rcsave{
  margin-left: -25px;
 
  margin-top: 28px;
  width: 140px;
}
 
 

.rccancel{
  width: 141px;
  margin-left: 20px;
  margin-top: 28px;
}
.purchasefor{
  margin-left:-20px;
}
.purchasedate{
  margin-left:-20px;
}
.purchasesave{
  width: 150px;
  margin-left: -25px;
  margin-top: 28px;

 
}
.purchaseCancal{
  margin-left: 40px;
  width: 150px;
  margin-left: 23px;
}
.salessave{
  width: 140px;
  margin-top: 28px;
  margin-left: -15px;
}
.salescancel{
  width: 150px;
  margin-top: 28px;
  margin-left: 20px;
}
.Usercontactsavebutton{
  margin-left: -20px;
  margin-top: 18px;
  width: 105px;
}


 
}





















