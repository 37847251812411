
#select{font-size: 13px;}
.bg-color{
    background-color:rgb(238, 230, 230);
    
   height: 35px;
   line-height: 32px;
}
.Savedata{
    font-weight: bold;

}